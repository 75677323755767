var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-daamges-list"},[_c('v-divider'),_c('v-card-text',[_c('v-row',[(_vm.hasAccessTo('employee') || _vm.hasManyCompanies())?_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('m-company-filter',{attrs:{"hide-details":"","outlined":"","dense":""},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-select',{attrs:{"hide-details":"","outlined":"","dense":"","clearable":"","items":_vm.statuses,"label":"Status"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":"","color":item.color}},[_vm._v("mdi-circle")]),_c('span',[_vm._v(_vm._s(item.text))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":item.color}},[_vm._v("mdi-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)]}}]),model:{value:(_vm.model.status),callback:function ($$v) {_vm.$set(_vm.model, "status", $$v)},expression:"model.status"}})],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.state.headers,"items":_vm.state.items,"item-key":"id","options":_vm.state.options,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"footer-props":{ itemsPerPageOptions: [20, 30, 40, 50] },"mobile-breakpoint":1264,"group-by":_vm.groupBy},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
return [((_vm.hasAccessTo('employee') || _vm.hasManyCompanies()) && items[0])?[_c('td',{attrs:{"colspan":headers.length}},[(items[0].vehicle && items[0].vehicle.company)?_c('strong',[_vm._v(_vm._s(items[0].vehicle.company.name))]):_c('strong',[_vm._v("Pozostałe szkody")])])]:_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [(item.createdAt)?_c('span',[_vm._v(_vm._s(_vm.$moment(item.createdAt).format('DD.MM.YYYY / HH:mm')))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.vehicle",fn:function(ref){
var item = ref.item;
return [(item.vehicle)?_c('v-list-item',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-list-item-action',{staticClass:"mr-2"},[_c('v-btn',{attrs:{"icon":"","to":("/vehicle/" + (item.vehicle.id))}},[_c('v-icon',[_vm._v("mdi-car")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.vehicle.registration))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.vehicle.name))])],1)],1):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.description)?_c('div',{staticClass:"my-2 pa-2 grey lighten-4 rounded",domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.entities(item.description)))}}):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"small":"","label":""}},[_vm._v(_vm._s(_vm.getStatusText(item.status)))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('o-damage-edit',{attrs:{"id":item.id},on:{"update":_vm.fetchData},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }