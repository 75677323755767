






























































import useString from "@/use/string";
import useUser from "@/use/user";
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  reactive,
} from "@vue/composition-api";
import { watchDebounced } from "@vueuse/shared";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  components: {
    MCompanyFilter: () => import("@/components/molecules/m-company-filter.vue"),
    ODamageEdit: () => import("@/components/organisms/o-damage-edit.vue"),
  },
  setup(_, { root }) {
    const { entities, nl2br } = useString();
    const { hasAccessTo, hasManyCompanies } = useUser({ root });

    const state = reactive({
      headers: computed(() => {
        const headers = [];

        headers.push({
          text: "Data",
          value: "createdAt",
          width: 200,
          sortable: false,
        });

        headers.push({
          text: "Pojazd",
          value: "vehicle",
          width: 300,
          sortable: false,
        });

        headers.push({
          text: "Opis",
          value: "description",
          width: 500,
          sortable: false,
        });

        headers.push({
          text: "Status",
          value: "status",
          width: 200,
          sortable: false,
        });

        headers.push({
          text: "",
          value: "actions",
          sortable: false,
          align: "end",
          width: 1,
        });

        return headers;
      }),
      items: [] as any[],
      options: {
        page: 1 as number,
        itemsPerPage: 20 as number,
        sortBy: [] as string[],
        sortDesc: [] as boolean[],
        multiSort: false as boolean,
        mustSort: false as boolean,
      },
      total: 0,
      loading: true,
      queryLoading: false,
      loaded: false,
      contactTimer: null as any,
    });

    const model = reactive({
      company: null as any,
      status: "in_progress" as "in_progress" | "completed" | null,
    });

    onMounted(() => {
      if (root.$route.name === "panel.vehicle.list") {
        const query = root.$route.query;

        state.queryLoading = true;

        nextTick(() => {
          if (
            query["damages.page"] &&
            typeof query["damages.page"] === "string"
          )
            state.options.page = parseInt(query["damages.page"]);
          if (
            query["damages.items_per_page"] &&
            typeof query["damages.items_per_page"] === "string"
          )
            state.options.itemsPerPage = parseInt(
              query["damages.items_per_page"]
            );
          if (
            query["damages.status"] &&
            typeof query["damages.status"] === "string"
          )
            model.status = query["damages.status"] as
              | "in_progress"
              | "completed"
              | null;

          setTimeout(() => (state.queryLoading = false), 2000);
        });
      }
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const { page, itemsPerPage } = state.options as DataOptions;

      state.loading = true;

      const filter = {
        companyId: model.company ? model.company.id : undefined,
        status: model.status ?? undefined,
      };

      axiosInstance
        .get("damage", {
          params: { page, itemsPerPage, ...filter },
        })
        .then(({ data: { damages, total } }) => {
          state.items = damages;
          state.total = total;
        })
        .catch(() => {
          state.items = [];
          state.total = 0;
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    watchDebounced(() => [state.options, model], fetchData, {
      deep: true,
      debounce: 500,
      maxWait: 5000,
      immediate: true,
    });

    const groupByCompany = computed(
      () =>
        (hasAccessTo.value("employee") || hasManyCompanies.value()) &&
        !(model.company || null)
    );

    const groupBy = computed(() =>
      groupByCompany.value ? "vehicle.company.id" : null
    );

    const statuses = [
      { text: "W trakcie", value: "in_progress", color: "primary" },
      { text: "Zakończone", value: "completed", color: "success" },
    ];

    const getStatusColor = (status: string) =>
      statuses.find((s) => s.value === status)?.color ?? undefined;

    const getStatusText = (status: string) =>
      statuses.find((s) => s.value === status)?.text ?? undefined;

    return {
      entities,
      nl2br,
      hasAccessTo,
      hasManyCompanies,
      state,
      model,
      fetchData,
      groupBy,
      statuses,
      getStatusColor,
      getStatusText,
    };
  },
});
